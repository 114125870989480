<template>
<div class="Prediction">
   <div class="uploadBox l">
      <div class="detailsBox">
         <div class="box">
            <p class="l">
               {{ $t("Prediction.MdmName") }}
            </p>
            <span class="r">{{ name }}</span>
         </div>
         <!-- <div class="box">
            <p class="l">
               {{ $t("Prediction.name") }}
            </p>
            <span class="r">{{ task_name }}</span>
         </div>
         <div class="box">
            <p class="l" style="line-height: 32px">
               {{ $t("Prediction.versions") }}
            </p>
            <span class="r">
               <el-select
                  size="small"
                  v-model="optionsData"
                  :placeholder="this.$t('Setting.select1')"
               >
                  <el-option
                     v-for="item in options"
                     :key="item._id"
                     :label="item.version"
                     :value="item.id"
                  />
               </el-select>
            </span>
         </div> -->
      </div>
      <el-upload
         ref="uploadRef"
         class="upload"
         :on-remove="upClearFiles"
         drag
         :auto-upload="false"
         :http-request="changFile"
         action=""
         multiple
         accept="image/*">
         <div class="uploadText">
            {{ $t("Prediction.uploadText") }}
         </div>
         <el-button
            type="primary"
            style="width: 116px"
            round>
            {{ $t("Prediction.upload") }}
         </el-button>
      </el-upload>
      <div :class="{ BottomButtom: classTrue, BottomButtomA: csvDownloadTrue }">
         <el-button
            type="primary"
            class="r"
            style="width: 98px"
            @click="uploadFile">
            {{ $t("Prediction.button") }}
         </el-button>
         <!-- <el-button
            type="primary"
            class="r"
            v-show="csvDownloadTrue"
            style="width: 98px; margin-right: 30px"
            @click="csvDownloadFn">
            {{ $t("Prediction.download") }}
         </el-button> -->
      </div>
   </div>
   <div class="imgBox">
    <el-card :body-style="{ padding: '0px' }" style="width: 200px;"  v-for="item in imgList" :key="item">
       <el-image :src="item.src" class="image" style="width: 200px;"/>
      <div style="padding: 14px;">
        <span>{{ $t('AutoDL.DL.Preview.zuyly8') }}{{item.name}}</span>
        <p>{{ $t('AutoDL.DL.Preview.otvi0u') }}{{item.value}}</p>
      </div>
    </el-card>
</div>
   <div v-show="!beas64img" class="hint r">
      <div class="hintBox">
         <i v-show="hintText" class="el-icon-warning-outline" />
         <span> {{ hintText }}</span>
      </div>
   </div>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            circle
            @click="lastStep" />
      </el-tooltip>
   </section>
</div>
</template>

<script>
// import SparkMD5 from 'spark-md5'
import {
   mapState
} from "vuex";
export default {
   data() {
      return {
         form: {
            task_id: "",
            dataset_id: "",
            count: ""
         },
         imgUrl: "",
         imgUrlList: [],
         upTrue: false,
         csvDownload: "",
         classTrue: true,
         csvDownloadTrue: false,
         options: "",
         hintText: "",
         fileList: [],
         beas64img:[],
         imgList:[],
         name:""
      };
   },
   // watch:{fileList:this.toBeas64()},
   computed: {
      ...mapState(["task_id_DL",  "dataset_id_DL", ]),
   },
   methods: {
      getTask() {
         if (this.task_id_DL) {
            let url = `${window.apis.AUTODL_TASK}${this.task_id_DL}/`
            this.axios(url).then(
               res => {
                  this.name = res.data.task_name
               }
            )
         }
      },
      // 移除文件，文件列表为0移除下载
      upClearFiles(file, fileList) {
         if (fileList.length == 0) {
            this.csvDownloadTrue = false;
         }
         this.imgUrl = "";
         this.hintText = "";
         this.fileList = []
      },
      lastStep() {
         this.$router.push({
            name: "PredictionDL",
         });
      },
      changFile(file) {
         console.log(file)
         let _this = this
         this.fileList.push(file.file)
         let reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onloadend = function () {
               _this.beas64img.push({src:this.result,name:file.file.name})
            }
      },
      submit() {
         if (this.upTrue) {
            this.$refs.uploadRef.submit();
         } else {
            this.uploadFile();
         }
      },
      csvDownloadFn() {
         window.open(this.csvDownload, "_self");
      },
      uploadFile(param) {
         this.$refs.uploadRef.submit()
         let url = window.apis.KERAS_PREDICT;
         let downLoadUrl = window.apis.SERVER + window.apis.SYS_DOWNLOAD;
         let data = new FormData();
         const loading = this.$loading({
            lock: true,
            text: this.$t("Prediction.model_prediction"),
            background: "rgba(255, 255, 255, 0.4)",
         });
         if (param) {
            this.form = {
               task_id: this.task_id_DL,
               dataset_id: this.dataset_id_DL,
               count: ""
            };
            for (let key in this.fileList) {
               this.form[`file${key}`] = this.fileList[key]
            }
            this.form.count = this.fileList.length
            for (let key in this.form) {
               data.append(key, this.form[key]);
            }
            this.axios({
                  url: url,
                  method: "post",
                  data: data,
                  onUploadProgress: (progressEvent) => {
                     let num = ((progressEvent.loaded / progressEvent.total) * 100) | 0;
                     param.onProgress({
                        percent: num
                     });
                  },
               })
               .then((res) => {
                  loading.close();
                  if (res.status == 201 || res.status == 200) {
                     Object.keys(res.data.result).forEach((key)=>{
                        for(let key2 in this.beas64img){
                           if(this.beas64img[key2].name == key){
                              this.beas64img[key2].value = res.data.result[key]
                              this.imgList.push(this.beas64img[key2])
                           }
                        }
                     })
                     this.$message({
                        message: this.$t('config') == 'zh' ? res.data.zh : res.data.en,
                        type: "success",
                     });
                  }
               })
               .catch(function (error) {
                  loading.close();
               });
         }
      },
   },
   mounted() {
      this.getTask()
   },
};
</script>

<style lang="scss" scoped>
.detailsBox {
   width: 100%;
   margin: 0px 0 20px 0;
   font-size: 14px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   text-align: left;
   overflow: hidden;

   .box {
      width: 100%;
      height: auto;
      padding: 5px 10px;
      overflow: hidden;
      border-bottom: 1px solid rgba(218, 223, 231, 0.5);

      p {
         width: 25%;
         font-weight: 500;
      }

      span {
         width: 75%;
         text-align: left;
      }
   }

   div:hover {
      background: rgba(246, 247, 248, 0.5);
   }

   div:last-of-type {
      border: 0px;
   }
}
.imgBox {
   display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}
.Prediction {
   overflow: hidden;
   margin-top: 30px;

   .hint {
      position: relative;
      width: 60%;
      height: 445px;
      border: 1px solid rgba(218, 223, 231, 0.5);

      .hintBox {
         position: absolute;
         left: 50%;
         top: 20vh;
         transform: translate(-50%, -50%);
         font-size: 25px;
         font-weight: 500;
         color: rgba(92, 105, 123, 0.8);
         display: block;
      }
   }
}

::v-deep .el-upload {
   width: 100%;
}

::v-deep .el-upload-dragger {
   width: 100%;
   height: 319px;
   border: 1px solid rgba(218, 223, 231, 0.5);
   border-radius: 0px;
}

.uploadBox {
   width: calc(100% - 1053px);
   min-width: 403px;
   height: auto;
   position: relative;
   margin-bottom: 70px;

   .uploadText {
      margin: 116px 0 33px 0;
      font-size: 18px;
      font-weight: 500;
      color: #999999;
   }

   .BottomButtom {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -70px;
      width: 100%;
   }

   .BottomButtomA {
      width: 100%;
   }
}

section {
   position: fixed;
   right: 100px;
   bottom: 50px;
   z-index: 100;

   .Step {
      margin: 0 15px;
      box-shadow: 0px 0px 7px #333333;
   }
}
</style>
